$(function() {

    Fancybox.bind('.js-open-modal', {
        on: {
            done: (fancybox, slide) => {
                // debugger;
            
          },
        },
        trapFocus: false,
        autoFocus: false,
        placeFocusBack: false,
        dragToClose: false,
      });

    // -------------------------------------------------

    // let formEl = document.getElementById('form-order');

    // if (formEl) {

    //     formEl.addEventListener('submit', function (evt) {
    //         evt.preventDefault();

    //         const fancybox = Fancybox.show([
    //             {
    //             src: "#success",
    //             type: "inline",
    //             }
    //         ]);

    //     });
    // }

    $(".formx").submit(function (e){
        e.preventDefault();
        var form_data = $(this).serialize();
        $.ajax({
            type: "POST",
            url: "send.php",
            data: form_data,
            success: function (data) {
                console.log(data);
                $('#call_modal')[0].reset();
                $('#form-order')[0].reset();
                const fancybox = Fancybox.show([{
                        src: "#success",
                        type: "inline",
                    }
                ]);
            }
        });
    });

})