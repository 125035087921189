$(function() {
	let headerEl = document.querySelector('.header');
	if (!headerEl) return;

	let burgerBtn = headerEl.querySelector('.js-burger-btn');
	
	burgerBtn.addEventListener('click', function () {
		headerEl.classList.toggle('open-menu');
	});	

	$('.js-login-btn').click(function(){
		var pos = $(this).position();
		var left = pos.left - (500 - $(this).width())/2;
		var top = pos.top + $(this).height() + 80;
		window.open(this.href,'','menubar=no,toolbar=no,location=no,personalbar=no,dependent=yes,minimizable=no,dialog=yes,resizable=no,scrollbars=yes,height=745,width=500,left='+left+',top='+top);
		return false;
	});

})