$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    const $targetElement = $($.attr(this, 'href'));
    const indent = this.dataset.scrollindent || 0;
    const indentMob = this.dataset.scrollindentmob || 0;

    if (window.matchMedia('(max-width: 768px)').matches) {
      $('html, body').animate({        
        scrollTop: $targetElement.offset().top - Number(indentMob)
      }, 500);
    } else {
      $('html, body').animate({
        scrollTop: $targetElement.offset().top - Number(indent)
      }, 500);
    }
  
});